$f12: 0.75rem;
$f13: 0.813rem;
$f14: 0.875rem;
$f15: 0.938rem;
$f16: 1rem;
$f17: 1.063rem;
$f18: 1.125rem;
$f20: 1.25rem;
$f24: 1.5rem;

@mixin flex($align: center, $justify: center, $direction: row) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
}

@mixin btn() {
  height: 2.938rem;
  border: var(--basic-border);
  border-radius: 8px;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: bold;
  padding: 14px 20px;
  transition-duration: 200ms;
  cursor: pointer;
  white-space: nowrap;
}

@mixin basic-btn() {
  @include btn;
  background: radial-gradient(circle, rgba(211,55,83,1) 0%,
          rgba(209,54,96,1) 50%, rgba(199,45,101,1) 100%);
  color: white;
  &:disabled {
    background: var(--lightgrey);
  }
  &:hover:not(:disabled) {
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%),
    radial-gradient(12158.65% 140.68% at 99.42% 0%, #C72D65 0%, #D23760 48.44%, #D23755 100%);
  }
}

@mixin secondary-btn() {
  @include btn;
  background: #222222;
  color: white;
  &:disabled {
    background: var(--lightgrey);
  }
  &:hover:not(:disabled) {
    filter: brightness(30%);
  }
}

@mixin line-btn() {
  @include btn;
  background: white;
  border: var(--border-light);
  color: #222222;
  &:disabled {
    color: var(--lightgrey);
  }
  &:hover:not(:disabled) {
    background: #F7F7F7;
  }
}

@mixin line-accent-btn() {
  @include btn;
  background: white;
  border: 2px solid var(--accent) !important;
  color: var(--accent);
  &:disabled {
    color: var(--lightgrey);
  }
  &:hover:not(:disabled) {
    background: white;
  }
}

@keyframes appear-from-bottom {
  to{
    transform: translateY(0);
    opacity:1;
  }
}

@mixin appear-animation($translate: -20px, $duration: 0.7s, $opacity: 1) {
  transform: translateY($translate);
  opacity: $opacity;
  animation: appear-from-bottom $duration ease forwards;
}

@mixin basic-input() {
  font-family: "Pretendard";
  border: none;
  color: #222222;
  font-size: $f16;
  background: transparent;
  outline: none;
  &::placeholder {
    color: #717171;
  }
  &:focus {
    outline: none;
  }
}

@mixin light-rounded-box {
  @include flex();
  border-radius: 18px;
  background: #F7F7F7;
}

@mixin tooltip {
  position: absolute;
  background: #FF385C;
  border-radius: 4px;
  opacity: 0.9;
  padding: 10px;
  animation: 200ms fade-in ease-in-out forwards;
  p {
    color: white;
    font-size: $f14;
  }
  &::after {
    position: absolute;
    content: "";
    background: #FF385C;
    height: 8px;
    width: 16px;
    opacity: 0.9;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}