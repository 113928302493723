@import "./variables.scss";

.playground-page {
  width: 100%;
  min-height: 100vh;
  background: var(--bg-light);
  position: relative;

  header {
    @include flex();
    width: 100%;
    max-width: 500px;
    height: 54px;
    padding: 9px 16px;
    box-sizing: border-box;
    position: fixed;
    z-index: 50;
    top: 0;
    transition-duration: 200ms;
    & > svg path {
      fill: #FFFFFF;
    }

    h1 {
      color: white;
      font-size: 24px;
      font-weight: 900;
      flex: 1;
    }

    &.top {
      background: transparent;
      h1 {
        color: white;
      }
      & > svg path {
        fill: #FFFFFF;
      }
    }
    &.not-top {
      background: white;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
      h1 {
        color: var(--text-main);
      }
      & > svg path {
        fill: #222222;
      }
    }

    .my-points {
      @include flex();
      gap: 5px;
      margin: 0 14px;
      padding: 8px 12px 8px 6px;
      border-radius: 40px;
      background: #222;
      color: white;
      font-size: $f15;
      span {
        margin-right: 4px;
      }
    }
  }

  .top-placeholder {
    width: 100%;
    height: 238px;
    background: transparent;
  }

  & > .banner {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 500px;
    height: 238px;
    background: linear-gradient(-126deg, #6A1B37 0%, #D0346B 100%);
    overflow: hidden;
    @include flex(flex-start, flex-start, column);
    padding: 54px 22px 34px 20px;
    color: #FFFFFF;
    font-family: "PretendardLight";
    p {
      opacity: 0.5;
      font-size: $f13;
      margin-bottom: 44px;
    }

    & > div {
      @include flex();
    }
    & > div:first-of-type {
      gap: 5px;
      span {
        font-size: $f14;
      }
      small {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 30%);
        font-size: $f12;
        line-height: 16px;
        text-align: center;
        margin: 0 4px;
      }
    }
    & > div:last-of-type {
      gap: 16px;
      margin-top: 12px;
      span {
        font-size: 32px;
        font-weight: bold;
      }
      button {
        background: transparent;
        padding: 9px 12px;
        font-size: $f14;
        font-weight: bold;
        color: #FFF;
        border: 2px solid #FFF;
        border-radius: 8px;
        &:not(:disabled):hover {
          background: #FFFFFF15;
          cursor: pointer;
        }
        &:disabled {
          opacity: 0.5;
        }
      }
    }

    & > img {
      position: absolute;
      right: 22px;
      bottom: 34px;
      max-height: 102px;
      object-fit: contain;
    }
  }

  & > h2 {
    font-size: 22px;
    font-weight: bold;
    line-height: 130%;
    margin: 30px 24px 12px;
    white-space: break-spaces;
  }

  article {
    width: 100%;
    min-height: 300px;
    background: #FFF;
    border-radius: 24px 24px 0 0;
    margin-top: -24px;
    z-index: 9;
    position: relative;
    padding: 20px 0;
    & > hr {
      background: #EBEBEB;
      height: 1px;
      border: none;
      margin: 12px 24px !important;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }
  }
}

.ad-banner {
  background: linear-gradient(107deg, #FE9BB2 0%, #FFEAD6 53%, #FFDCF7 100%);
  height: 108px;
  border-radius: 16px;
  margin: 0 16px 20px;
  padding: 20px 20px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  &:hover, & * {
    cursor: pointer !important;
  }
  span {
    font-size: $f12;
    color: var(--accent);
    margin-bottom: 5px;
  }
  p {
    font-weight: 900;
  }
  img {
    position: absolute;
    bottom: 0;
    right: 16px;
  }
}

.ad-view-box {
  position: relative;
  display: flex;
  gap: 12px;
  margin: -20px 20px 20px;
  width: calc(100% - 40px);
  min-height: 80px;
  padding: 20px;
  border-radius: 18px;
  background: #FFF;
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.08);
  & > div:nth-child(2) {
    width: 100%;
    & > p:first-child {
      color: var(--primary);
      font-size: $f12;
    }
    & > p:last-child {
      color: var(--text-main);
      font-size: $f15;
      font-weight: bold;
      margin-top: 3px;
    }
  }
  img {
    border-radius: 50%;
  }
  button {
    @include flex();
    height: 40px;
    width: 115px;
    font-size: $f15;
    font-weight: bold;
    padding: 10px 16px 10px 12px;
    gap: 4px;
  }
}

.white-card {
  width: 100%;
  min-width: 310px;
  border-radius: 18px;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #E2EC6E;
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.08);
}

.mini-games {
  width: 100%;
  @include flex(flex-start, center, column);
  padding: 4px 24px 8px;

  & > div:first-child {
    width: 100%;
    display: flex;
    align-items: center;
    h2 {
      font-size: 20px;
      margin-right: auto;
    }
    span {
      font-size: $f15;
      margin-right: 8px;
      color: var(--grey);
      cursor: pointer;
    }
    svg {
      cursor: pointer;
      path {
        fill: var(--grey);
      }
    }
  }

  .game-text {
    font-size: $f13;
    margin: 10px 0 4px;
    font-family: 'PretendardLight';
  }
}

.next-game {
  display: flex;
  width: 100%;
  padding: 8px 0 12px;
  img {
    border-radius: 16px;
    object-fit: contain;
    border: 1px solid #EBEBEB;
    margin-right: 16px;
  }
  & > div:first-of-type {
    gap: 8px;
    @include flex(flex-start, center, column);
    & > div {
      @include flex();
      gap: 1px;
      & > span {
        padding: 5px 8px;
        font-size: $f12;
        height: 14px;
      }
      & > span:first-of-type {
        background: var(--accent);
        color: #FFF;
        border-radius: 6px 0 0 6px;
      }
      .before-start-tag {
        border-radius: 6px !important;
      }
      & > span:nth-child(2) {
        background: #222;
        color: #FFF;
        border-radius: 0 6px 6px 0;
      }
    }
    & > p.text-grey {
      font-size: $f14;
    }
  }
}

.next-game-btn {
  display: flex;
  gap: 8px;
  width: 100%;
  & > button:first-of-type {
    height: 40px;
    padding: 12px 20px;
    @include line-btn();
  }
  & > button {
    height: 40px !important;
    padding: 11px 20px !important;
  }
}

.offer-wall {
  background: #FFF;
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  li {
    padding: 16px 20px 16px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    & > div:first-child {
      width: 48px;
      height: 48px;
      border-radius: 8px;
      background: #F7F7F7;
      @include flex();
    }
    & > div:nth-child(2) {
      @include flex(flex-start, center, column);
      gap: 4px;
      margin: 0 12px;
      p {
        font-size: $f14;
        font-family: 'PretendardLight';
      }
    }
    & > button {
      height: 36px;
      font-size: $f14;
      margin-left: auto;
      padding: 10px 12px;
      width: fit-content;
    }
  }
}



.mini-game-card {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  overflow: hidden;
  height: 145px;
  padding: 20px 24px;
  & > div {
    @include flex(flex-start, center, column);
    gap: 8px;
  }
  h3 {
    font-size: $f18;
    font-weight: bold;
  }
  p {
    white-space: break-spaces;
  }
  img {
    position: absolute;
    right: 24px;
    top: 0;
    object-fit: contain;
    height: 150px;
  }
}

.mini-game-card:first-child {
  padding: 10px 24px;
  p {
    font-size: .875rem;
    color: #5E5E5E;
  }
  img.button {
    position: initial;
    width: 45px;
    height: 45px;
    align-self: center;
  }
}

.ref-code {
  padding: 12px 0 0;
  margin-bottom: 24px;
  & > p {
    color: var(--Neutral-08, #5E5E5E);
    text-align: center;
    line-height: 130%;
    font-size: $f14;
    font-family: "PretendardLight";
    margin-bottom: 24px;
    white-space: break-spaces;
    span {
      font-family: "Pretendard";
      color: #D03660;
      font-weight: 900;
    }
  }
  & > div:nth-child(2) {
    @include light-rounded-box();
    gap: 8px;
    padding: 11px 18px;
    margin-bottom: 16px;
    span {
      font-weight: bold;
    }
    span:last-child {
      color: #D03660;
    }
  }

  button:last-of-type svg path {
    fill: white;
  }

  & > div:nth-child(3) {
    width: 100%;
    display: flex;
    gap: 8px;
    button:first-child {
      @include line-btn();
    }
    button:last-child {
      @include secondary-btn();
    }
    button {
      height: 40px !important;
      font-size: $f15 !important;
      @include flex();
    }
  }
}

.match-info {
  margin: 19px 24px;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  font-family: "PretendardLight";
  font-size: $f15;
  line-height: 140%;
}

.game-winner-info {
  width: 100%;
  background: #222;
  padding: 12px 0;
  overflow: hidden;
  & div {
    width: max-content;
    @include flex();
    gap: 8px;
    transform: translateX(100%);
    animation: horizontal-scroll 12s linear infinite;
  }
  p {
    color: white;
    font-size: $f14;
    font-family: "PretendardLight";
  }
}

@keyframes horizontal-scroll {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}


.tory-game-enter {

  & > article {
    background: var(--bg-light);
    padding: 16px;
  }
}

.commercial-card {
  @include flex(center, center, column);
  padding: 24px;
  h3 {
    max-width: 280px;
    text-align: center;
  }
  p {
    margin: 16px 0;
    color: var(--grey);
  }
  & > img {
    margin: 8px 0 19px;
  }
  button {
    @include flex();
    gap: 6px;
  }
}

.tory-img-swiper {
  margin: 12px 0;
  .swiper-slide {
    padding-bottom: 28px;
    &:nth-child(3n+1) > div {
      background: linear-gradient(97deg, #3F007F 0%, #C9006D 100%);
    }
    &:nth-child(3n+2) > div {
      background: linear-gradient(277deg, #006D86 2.71%, #000D82 100%);
    }
    &:nth-child(3n+3) > div {
      background: linear-gradient(277deg, #7C0569 0%, #0A0953 100%);
    }
    .event-slide {
      @include flex(flex-start, flex-start, column);
      height: 126px;
      padding: 24px;
      border-radius: 18px;
      box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.08);
      color: white;
      position: relative;

      span {
        font-size: $f13;
        font-weight: bold;
        margin-bottom: auto
      }
      p:nth-child(2) {
        font-size: $f13;
        font-family: PretendardLight;
        color: #FFFFFF;
        opacity: 0.8;
      }
      p:last-of-type {
        font-size: $f17;
        font-weight: bold;
      }
      img {
        position: absolute;
        top: 25px;
        right: 20px;
      }
    }
  }
  .swiper-pagination-bullet {
    background: rgba(34, 34, 34);
    opacity: 0.3;
    margin: 0 2px !important;
    &.swiper-pagination-bullet-active {
      width: 20px;
      opacity: 1;
      border-radius: 30px;
      background: #D03660;
      transition-duration: 200ms;
    }
  }
}

.game-area-header {
  margin: 34px 0 12px;
  @include flex(center, space-between);
  padding: 0 8px;
  gap: 10px;
  & > img {
    margin-right: auto;
    cursor: pointer;
  }
}

.game-intro-modal {
  .dialog-box {
    padding: 24px 0 !important;
    margin: 20px 20px;
  }
  .dialog-content img {
    width: 100%;
    margin: 0 2px;
  }
  .dialog-title, .alert-action-btn {
    padding: 0 24px;
  }
}

button.info-btn {
  background: transparent;
  border: none;
  outline: none;
  color: var(--grey);
  font-size: $f16;
  height: 20px;
}

.tory-info-btn {
  background: transparent;
  border: none;
  outline: none;
  color: var(--grey);
  font-size: $f16;
  height: 20px;
}

.game-area-cards {
  .basic-tabs {
    border-radius: 18px 18px 0 0;
    article {
      width: 100%;
      background: white;
      padding-bottom: 18px;
    }
  }

  .deal-card {
    width: 100%;
    @include flex(flex-start, flex-start, column);
    padding: 24px 24px 20px 24px;
    &:not(:last-child) {
      border-bottom: 1px solid #EBEBEB;
    }
    & > div:first-child {
      position: relative;
      width: 100%;
      @include flex();
      border-radius: 8px;
      & > span {
        color: white;
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: $f12;
        border-radius: 4px;
        background: #222;
        padding: 5px 8px;
      }
      & > .card-img {
        width: 100%;
        height: 313px;
        background-size: cover !important;
        background-position: center !important;
        border-radius: 8px;
      }
      & > div:last-child {
        width: 100%;
        height: 40px;
        position: absolute;
        bottom: 0;
        left: 0;
        gap: 4px;
        @include flex();
        color: white;
        border-radius: 0 0 8px 8px;
        background: rgba(34, 34, 34, 0.60);
      }
    }
    h4 {
      font-size: $f16;
      font-weight: bold;
      margin-bottom: 8px;
    }
    & > p {
      margin-bottom: 16px;
      font-size: $f14;
      font-family: PretendardLight;
    }
    .basic-btn:disabled {
      &.bg-color-closed {
        background: #DDD !important;
      }
      &.bg-color-waiting, &.bg-color-coming {
        background: radial-gradient(1413.54% 103.95% at -3.95% 100%, #D33753 0%, #D13660 52.83%, #C72D65 100%) !important;
      }
      cursor: default;
    }
    .basic-btn.bg-color-active, .basic-btn.bg-color-active:hover {
      background: #8AAD26 !important;
    }
  }

  .link-btn button:last-of-type {
    color: #C03765;
    font-weight: bold;
    svg path {
      fill: #C03765;
    }
  }
}

.deal-status {
  color: var(--primary);
  font-size: $f12;
  margin: 16px 0 2px;
}


.address-info-check {
  display: grid;
  grid-template-columns: 55px 1fr;
  gap: 6px;
  background: var(--bg-pink);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 8px;
  span {
    word-break: break-word;
    &:nth-child(odd) {
      text-align: right;
    }
  }
}


.notification-filter {
  display: flex;
  gap: 8px;
  margin: 24px 24px 16px;
  & > div {
    color: #000;
    min-width: 56px;
    padding: 9px 12px;
    border-radius: 50px;
    border: 1px solid var(--lightgrey);
    background: #FFF;
    font-size: $f15;
    &.selected {
      color: #FFF;
      border: 1px solid rgba(34, 34, 34, 0.05);
      background: var(--accent);
    }
  }
}

.notification-list {
  ul {
    list-style-type: none;
    margin-block-start: 0;
    padding-inline-start: 0;
  }
  li {
    position: relative;
    padding: 24px 20px;
    span {
      font-size: $f12;
    }
    .timestamp-and-new {
      position: absolute;
      top: 24px;
      right: 20px;
      display: flex;
      gap: 6px;
      .timestamp {
        min-width: 50px;
        font-size: $f12;
      }
    }
    &.unread {
      background: #FEF8F6;
    }
    p.notification-content {
      line-height: 1.5;
      margin-top: .5rem;
      white-space: pre-line;
    }
  }
}


.store-item-list {
  & > div {
    @include flex(center, flex-start);
    gap: 16px;
    width: 100%;
    padding: 12px 20px;
    background: white;

    .img-wrapper {
      @include flex();
      border-radius: 18px;
      border: 1px solid rgba(34, 34, 34, 0.05);
      min-width: 100px;
      width: 100px;
      min-height: 100px;
      height: 100px;
      background: var(--bg-light);
    }
    p {
      font-size: $f14;
      margin: 4px 0 8px;
    }
    & > div > div {
      display: flex;
      gap: 8px;
      span {

      }
      button:first-of-type {
        @include flex();
        gap: 4px;
        width: 115px;
        height: 40px;
        padding: 10px 16px 10px 12px;
        font-size: $f15;
      }
      button:last-of-type {
        @include flex();
        @include secondary-btn();
        height: 40px;
        padding: 0 16px 0 12px;
        gap: 4px;
        border-radius: 8px;
        width: 82px;
      }
    }
  }

  .disabled-item {
    img {
      opacity: 0.8;
    }
    & > div h4, p.text-grey {
      color: #B0B0B0 !important;
    }
  }
}

.item-purchase-points {
  @include light-rounded-box();
  height: 30px;
  margin: 0 auto 20px;
  max-width: 100px;
  span {
    font-weight: bold;
    font-size: $f18;
  }
}

.reward-img {
  @include flex(center, center, column);
  padding: 32px;
  text-align: center;
  & > div:first-of-type {
    width: 120px;
    height: 120px;
    background-size: contain !important;
    background-position: center !important;
    margin-bottom: 20px;
    border: 1px solid rgba(34, 34, 34, 0.05);
    border-radius: 10px;
  }
  & > p {
    font-size: $f18;
    font-weight: bold;
    margin-bottom: 24px;
  }
  & > span:not(.barcode-text) {
    font-size: $f14;
    margin-bottom: 8px;
  }
  .remained-time {
    font-size: 24px !important;
    font-weight: bold;
  }
}

.reward-detail {
  background: var(--bg-light);
  padding: 32px 24px;
  @include flex(flex-start,flex-start, column);
  gap: 16px;
  h4 {
    font-size: $f18;
  }
  ol {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 18px;
    color: var(--grey);
    line-height: 150%;
    font-size: $f15;
  }
}


.barcode {
  width: 100%;
  height: 103px;
  // background: #222222;
  margin: 0 33px;
}

.barcode img {
  height: 100%;
}

.barcode-text {
  font-weight: bold;
  margin: 10px 33px 30px;
}

.steps-card {
  @include flex(center, flex-start, column);
  padding: 24px;
  margin-bottom: 12px;

  button {
    @include flex();
    gap: 6px;
  }

  & > p:first-of-type {
    font-size: $f14;
    margin: 12px 0;
  }
  & > p:last-of-type {
    font-size: $f12;
    width: 100%;
    text-align: right;
    margin: 8px 0 16px;
  }
}

.steps-progress-bar {
  position: relative;
  width: 100%;
  border-radius: 8px;
  height: 32px;
}

.energy-percent {
  position: absolute;
  top: 8px;
  font-size: $f14;
  font-weight: bold;
}

.no-content {
  height: 552px;
  @include flex(center, center, column);
  p {
    font-size: $f15;
    font-weight: normal;
    margin-top: 20px;
    color: #B0B0B0;
  }
}

.energy-modal {
  text-align: center;
  
  .dialog-content {
    & > p {
      margin: 16px;
      font-size: 1rem;
    }
    & > button:first-of-type {
      background: #8AAD26;
    }
  }
}

.popup-download-modal {
  .dialog-box {
    padding: 0;
    margin: 0;
    border-radius: 0;
    line-height: 0;
  }
  .dialog-title {
    display: none;
  }
  .dialog-content {
    & > .close {
      position: absolute;
      top: 10px;
      right:10px;
    }
    & > .buttons {
      position: absolute;
      bottom: 35px;
      width: 100%;
      & > .btn_container {
        display: flex;
        justify-content: center;
        & > a:first-child {
          margin-right: 5px;
        }
      }
    }
  }
}


.benefits-popup {
  & > img {
    border-radius: 12px;
    margin-bottom: 16px;
  }
  p {
    line-height: 130%;
    font-size: $f14;
    margin-top: 4px;
  }
  & > div {
    margin-top: 24px;
    width: 100%;
    @include flex(flex-start, flex-start);
    gap: 8px;
    & > button:first-child {
      @include line-accent-btn();
    }
  }
}

.attendance-page {
  article {
    width: 100%;
    background: #FFF;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > h1 {
      font-size: 22px;
      text-align: center;
      margin: 24px auto 12px;
    }
    & > p {
      font-size: $f15;
      text-align: center;
      margin: 0 auto 24px;
    }
  }
}

.attendance-check-box {
  width: 100%;
  min-height: 320px;
  margin-bottom: 10px;
  background: var(--bg-light);
  border-radius: 18px;
  padding: 32px;
  & > div {
    width: 100%;
    @include flex();
    gap: 16px;
  }
}

.attendance-check-info {
  padding: 32px 16px 13px;
  ul {
    list-style: disc;
    padding-inline-start: 16px;
    p, li {
      color: var(--grey);
      font-size: $f14;
      line-height: 140%;
    }
  }
}

.ad-confirm-modal, .point-success-modal {
  .dialog-title {
    font-size: 20px;
  }
  .dialog-box {
    min-width: 300px;
    text-align: center;
    button:first-of-type {
      margin-top: 20px;
    }
  }
  .dialog-content p {
    font-size: $f16;
    font-family: PretendardLight;
  }
}

.ad-confirm-modal {
  .dialog-content {
    button:last-of-type {
      @include line-btn();
      margin-top: 8px;
    }
    button {
      position: relative;
    }
    button img {
      position: absolute;
      left: 8px;
      bottom: 0;
    }
  }
}

#bg-reward {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  @include flex();
  z-index: 120;
  video {
    width: 100%;
    transform: translate(0, -50px);
    height: 300px;
    object-fit: cover;
  }
}


.random-luck-page {
  min-height: 580px;
  height: calc(100vh - 52px);
  background: var(--bg-pink);
  @include flex(center, flex-start, column);
  & > div:first-child {
    margin: 44px 0 10px;
    position: relative;
    p {
      max-width: 150px;
      padding: 12px 16px;
      border-radius: 24px;
      background: #FFF;
      font-size: $f15;
      text-align: center;
    }
    &::after {
      display: block;
      background: #FFF;
      content: "";
      width: 14px;
      height: 10px;
      position: absolute;
      bottom: -9px;
      left: calc(50% - 7px);
      clip-path: polygon(0 0, 50% 100%, 100% 0);
    }
  }
  & > button {
    margin: 24px auto;
    width: fit-content;
  }

  & > h1 {
    margin: 40px 0 12px;
    .text-accent {
      font-weight: 900;
    }
  }
  & > p {
    font-size: $f15;
    text-align: center;
    max-width: 185px;
  }
  & > div:last-of-type {
    padding: 16px 48px;
    font-size: $f14;
    text-align: center;
    background: rgba(255, 255, 255, 30%);
    margin:  auto 24px 24px;
    border-radius: 8px;
  }
}

.random-luck-cat {
  position: relative;
  height: 180px;
  width: 100%;
  margin-top: -8px;
  @include flex();
  & > img {
    position: absolute;
  }
  & > img:first-of-type {
    top: -26px;
    animation: zoom-in-n-out 2s ease infinite;
  }
}

@keyframes zoom-in-n-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.random-luck-done {
  height: calc(100vh - 60px);
  min-height: 500px;
  background: #FFF;
  padding: 34px 16px;
  @include flex(center, flex-start, column);
  & > div {
    flex: 1;
    padding-bottom: 100px;
    @include flex(center, center, column);
  }
  h3 {
    font-size: 20px;
    margin: 16px 0 8px;
  }
  button {
    height: 52px;
    margin: 20px 16px 0;
  }

  #bg-reward {
    min-height: 70%;
    margin: 50px 0 100px;
    video {
      transform: translate(0, -145px);
      height: 340px;
    }
  }
}


.yaong-lucky-spin-page {
  & > div:nth-child(2) {
    @include flex(center, flex-start, column);
    background: #FFFFFF;
    text-align: center;
    padding: 16px 24px 32px;

    h2 {
      font-size: 22px;
    }
    p {
      margin: 8px 0 16px;
    }
    & > button {
      @include flex();
      gap: 4px;
    }
    & > button:last-of-type {
      @include secondary-btn();
      margin-top: 8px;
    }
  }

  .today-spins {
    margin-top: 16px;
    gap: 3px;
    span:last-of-type {
      font-weight: bold;
    }
    strong {
      color: #FF385C !important;
    }
  }

  #bg-reward {
    top: 0 !important;
    bottom: auto;
    min-height: calc(50% + 60px);
    align-items: flex-end;
    video {
      height: auto;
    }
  }
}

.info-bubble {
  @include flex();
  background: #F7F7F7;
  border-radius: 48px;
  height: 40px;
  font-size: $f16;
  box-shadow: inset 0 2px 0 0 #DDDDDD;
  padding: 10px 16px;
}

.yaong-spin-wheel {
  height: 485px;
  width: 100%;
  background: linear-gradient(#F6D7DF 0%, #FFFFFF 100%);
  position: relative;
  display: flex;
  justify-content: center;
  img {
    position: absolute;
  }
  & > img:first-of-type {
    bottom: 62px;
  }
  & > img:nth-of-type(2) {
    bottom: 20px;
  }
  & > img:last-of-type {
    bottom: 152px;
  }
}

.yaong-spin-modal {
  .dialog-content > div {
    background: #F7F7F7;
    border-radius: 8px;
    @include flex();
    height: 33px;
    font-size: $f14;
    width: 100%;
    margin-top: 16px;
  }
  button:nth-of-type(2) {
    margin-top: 8px;
    @include line-accent-btn();
  }
}