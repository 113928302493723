@font-face {
  font-family: 'PretendardLight';
  src: url("./assets/fonts/Pretendard-Light.woff") format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url("./assets/fonts/Pretendard-Regular.woff") format('woff');
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: 'PretendardSemiBold';
  src: url("./assets/fonts/Pretendard-SemiBold.woff") format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url("./assets/fonts/Pretendard-Bold.woff") format('woff');
  font-display: swap;
  font-weight: bold;
}
@font-face {
  font-family: 'Pretendard';
  src: url("./assets/fonts/Pretendard-ExtraBold.woff") format('woff');
  font-display: swap;
  font-weight: 900;
}

:root {
  --primary: #F6475F;
  --accent: #D03660;
  --bg-pink: #F6D7DF;
  --grey: #5E5E5E;
  --bg-light: #F7F7F7;
  --lightgrey: #DDDDDD;
  --text-main: #222222;
  --text-light: #717171;
  --border-light: 1px solid #DDDDDD;
  --border-focus: 2px solid #222222;
  --error: #C13515;
}

body {
  -webkit-tap-highlight-color:transparent;
  margin: 0;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

body div, section, article {
  box-sizing: border-box;
}

h1, h2, h3, h4, p {
  margin-block-start: 0;
  margin-block-end: 0;
}

h1 {
  font-weight: 900;
  font-size: 1.75rem; // 28px
}

h3 {
  font-size: 1.125rem; // 18px
}

#root {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  background-image: url("./assets/images/pc_bg.png");
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  background: transparent;
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  border-radius: 0;
  border: 0 solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover,
*::-webkit-scrollbar-thumb:hover {
  background-color: #56585D;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}



