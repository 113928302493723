@import "./variables.scss";

.login-page {
  @include flex(center, center, column);
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%),
  radial-gradient(12158.65% 140.68% at 99.42% 0%, #C72D65 0%, #D23760 48.44%, #D23755 100%);

  & > h1 {
    color: #FFFFFF;
    font-size: 28px;
    font-weight: 900;
    margin-bottom: 16px;
    margin-top: 60px;
  }

  & > p:nth-child(2) {
    max-width: 165px;
    word-break: keep-all;
    text-align: center;
    color: #FFFFFF;
    font-size: $f16;
    font-family: "PretendardLight";
    line-height: 140%;
  }

  footer {
    max-width: 330px;
    word-break: break-word;
    text-align: center;
    color: #FFFFFF70;
    font-family: "PretendardLight";
    font-size: $f14;
    line-height: 140%;
    margin-top: 32px;
    a {
      text-decoration: underline;
      color: #FFFFFF !important;
    }
  }
}

.login-actions {
  width: 100%;
  padding: 0 24px;
  @include flex(center, center, column);
  transition: width 300ms;

  & > button {
    @include flex(center, space-between);
    display: flex;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    span {
      width: 100%;
      flex: 1;
      font-family: "Pretendard";
      font-weight: bold;
      line-height: 20px;
      font-size: $f16;
      color: #222;
    }
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
    /* &:nth-of-type(2) {
      background: #03C75A;
    } */
    &:nth-of-type(2) {
      background: #F7F7F7;
    }
    &:last-of-type {
      background: #FFFFFF;
    }
    &:first-of-type {
      background: #FEEB00;
    }
  }
}

.loading-circle {
  margin-top: 56px;
  & img {
    animation: 1s rotating ease-in infinite;
  }
}

@keyframes rotating {
  0% {
    transform: none;
  }
  100% {
    transform: rotate(360deg);
  }
}


.friend-code-modal {
  .dialog-content {
    & > p {
      margin: 8px 0 16px;
    }
    & > .friend-nickname {
      @include light-rounded-box();
      padding: 11px 18px;
      gap: 8px;
      font-size: $f15;
      margin-bottom: 20px;
      & > span:first-child {
        font-weight: bold;
      }
    }
  }
}

.welcome-modal {
  text-align: center;

  .dialog-content {
    & > p {
      margin: 16px;
    }
    & > button {
      width: 252px;
      margin-top: 12px;
    }
  }
}

.notification-modal {
  .dialog-title {
    & > h3 {
      font-size: 20px;
    }
  }
  .dialog-content {
    & > p {
      font-size: 16px;
      text-align: left;
      white-space: pre-line;
    }
  }
}

.update-modal {
  .dialog-title {
    text-align: center;
  }
  .dialog-content {
    & > p {
      font-size: 1rem;
      text-align: center;
      padding: 20px 0 24px;
    }
    & > div.version {
      color: grey;
      font-size: .875rem;
      text-align: center;
      padding-bottom: 24px;
    }
  }
}
