@import "./variables.scss";

.ticket-booking-page {

  & > div:first-child {
    position: relative;
    @include flex();
    padding: 16px;
    h1 {
      font-size: $f17;
    }
    svg {
      position: absolute;
      top: 16px;
      right: 25px;
    }
  }

  p {
    word-break: keep-all;
  }
}

.booking-tabs {
  height: 55px;
  width: 100%;
  background: #5E5E5E;
  box-shadow: -1px 0px 0px 0px rgba(255, 255, 255, 0.10) inset;
  @include flex();

  & > div {
    position: relative;
    flex: 1;
    color: #D3D3D3;
    text-align: center;
    height: 100%;
    padding: 17.5px;
    font-size: $f16;
    &:not(:last-child)::after {
      content: "";
      display: flex;
      right: -19px;
      top: 0;
      position: absolute;
      width: 20px;
      height: 55px;
      background-color: #5E5E5E;
      clip-path: polygon(0 0, 0 100%, 100% 50%);
      z-index: 50;
    }
    & > div {
      display: flex;
      right: -20px;
      top: 0;
      position: absolute;
      width: 20px;
      height: 55px;
      background-color: rgba(255, 255, 255, 0.10);
      clip-path: polygon(0 0, 0 100%, 100% 50%);
      z-index: 49;
    }
  }

  .tab-selected {
    background: #222;
    color: #FFF;
    &:not(:last-child)::after {
      background-color: #222;
    }
  }
}

.seat-select {
  @include flex(flex-start, flex-start, column);
  h2 {
    font-size: $f18;
  }
  padding: 20px 24px 20px;
  gap: 16px;
  & > div {
    display: flex;
  }
  & > div > div:not(#mapArea, #miniMap) {
    font-size: $f12;
    flex: 1;
    width: 100%;
    @include flex(flex-start, flex-start, column);
    gap: 4px;
    margin-left: 8px;
    & > div {
      display: flex;
      gap: 8px;
      @include flex(center, flex-start);
      & > div {
        padding: 7px;
        color: white;
        min-width: 28px;
      }
    }
  }

  .standard-seat, .bench-seat {
    border-radius: 4px;
  }

  .bungalow-seat {
    width: 24px;
    border-radius: 30.171px 2.743px 30.171px 30.171px;
    background: #8AAD26;
  }

  .standard-seat {
    box-sizing: border-box;
    width: 22px;
    padding: 7px 7px 7px 5px !important;
    background: #222222;
    border-color: #924FFF;
    border-left: 4px solid #924FFF;
    border-bottom: 4px solid #924FFF;
  }

  .bench-seat {
    background: #30337A;
  }
}

.map-screen {
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  overflow: overlay;
  background: #222222;
}

div#mapArea {
  position: absolute;
  border: 1px solid #F6475F;
  width: 50px;
  height: 50px;
  transition-duration: 200ms;
}


.page-action-btn {
  @include flex();
  width: 100%;
  padding: 25px;
  gap: 8px;
  & > .prev-btn {
    @include line-btn();
  }
}

.info-enter-page {
  background: var(--bg-light);
  h2 {
    font-size: $f18;
  }
  & > div {
    background: white;
    @include flex(flex-start, center, column);
    padding: 32px 24px;
  }

  & > br {
    height: 12px;
    width: 100%;
  }
}

.privacy-terms {
  width: 100%;
  & > .checkbox-wrapper:first-child {
    border-bottom: 1px solid #EBEBEB;
  }
  .checkbox-wrapper {
    padding: 16px 0;
    width: 100%;
  }
  & > div:not(.checkbox-wrapper) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    & > svg path {
      fill: var(--grey);
    }
    & > svg {
      cursor: pointer;
    }
  }
}

.payment-details {
  position: relative;
  padding-bottom: 0 !important;
  .payment-title {
    color: #717171;
    font-family: 'Pretendard';
    font-size: 12px;
    font-weight: 500;
  }
  .price {
    margin: 5px 0 16px;
    color: #222222;
    font-size: 28px;
    font-weight: 700;
    & > span {
      font-size: 16px;
      font-weight: 600;
    }
  }
  & > p {
    font-size: $f15;
  }
  dl {
    padding: 16px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 30px 30px;
    margin-block-start: 0;
    margin-block-end: 0;
    &:not(:last-of-type) {
      border-bottom: 1px dashed #D3D3D3;
      margin-bottom: 20px;
    }
    dt {
      color: #717171;
      font-size: $f14;
    }
    dd {
      margin-inline-start: 0;
      font-size: $f16;
      text-align: right;
    }
    dt:last-of-type, dd:last-of-type {
      font-weight: 700;
    }
  }

  .coupon-btn {
    cursor: pointer;
    @include flex();
    position: absolute;
    top: 34px;
    right: 24px;
    padding: 11.5px 13.5px;
    border-radius: 8px;
    border: 1px solid #D3D3D3;
    background: #FFF;
    span {
      color: #222;
      font-size: $f15;
      font-family: 'Pretendard';
    }
  }
}

.seat-map-svg {
  rect[id], path[id] {
    cursor: pointer;
  }
}


.has-coupon-mark {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--primary);
  position: absolute;
  border: 2px solid white;
  top: -3px;
  right: -3px;
}

.coupon-page {
  background: var(--bg-light);
  display: flex;
  flex-direction: column;
  height: 100%;

  & > div:first-child {
    @include flex(center, flex-start);
    padding: 14px 20px;
    background: white;
    h3 {
      flex: 1;
      width: 100%;
      text-align: center;
      font-size: $f17;
    }
  }
  & > div.coupon-search {
    display: flex;
    background: white;
    padding: 16px 24px 24px;
    input {
      @include basic-input();
      border-radius: 8px 0 0 8px;
      padding: 16px 12px;
      border: 1px solid #DDD;
      width: 100%;
    }
    button {
      border: none;
      outline: none;
      border-radius: 0 8px 8px 0;
      background: var(--primary);
      color: white;
      @include flex();
      width: 53px;
      min-width: 53px;
      height: 53px;
      min-height: 53px;
      svg path {
        fill: white;
      }
    }
  }

  article {
    border-top: 1px solid #EBEBEB;
  }
}


.coupon-list {
  & > div:first-child {
    padding: 12px 20px;
    background: #FEF8F6;
    @include flex(center, flex-start);
    gap: 8px;
    & > div {
      @include flex();
      border-radius: 50%;
      height: 24px;
      width: 24px;
      background: var(--primary);
    }
    p {
      font-size: $f14;
      font-weight: normal;
      span {
        color: var(--primary);
      }
    }
  }
  ul {
    background: white;
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }
  .intersection-observer {
    visibility: hidden;
    position: relative;
    top: -320px;
    width: 100%;
    height: 20px;
    border: 1px solid red;
  }
}

.coupon-item {
  @include flex();
  padding: 8px 24px;
  gap: 16px;
  & > div:nth-child(2) {
    width: 100%;
    & > p:first-of-type {
      font-size: $f12;
      font-weight: 500;
      margin-bottom: 4px;
      span {
        color: var(--accent);
      }
    }
    & > p:nth-of-type(2) {
      font-size: $f16;
      font-family: "PretendardSemiBold";
      margin-bottom: 4px;
    }
    .coupon-date {
      font-size: $f14;
      color: #5E5E5E;
    }
  }
  & > div.expired {
    & > p {
      color: #D3D3D3;
    }
    & > p:last-of-type {
      text-decoration-line: line-through;
    }
  }
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 18px;
  }
}

.coupon-page .apply-btn {
  margin-top: auto;
  padding: 34px 24px;
}

.no-coupon {
  height: 100%;
  @include flex(center, center, column);
  p {
    font-size: $f15;
    font-weight: normal;
    margin-top: 20px;
    color: #B0B0B0;
  }
}

.alert-modal {
  h3 {
    text-align: center;
    font-size: $f20;
    font-weight: bold;
  }
  .alert-msg {
    font-size: $f16;
    line-height: 130%;
    font-weight: 500;
    color: #222;
    text-align: center;
    width: 100%;
    margin: 8px 0 20px;
    word-break: keep-all;
  }
}

.alert-action-btn {
  @include flex();
  width: 100%;
  gap: 8px;
  & > .prev-btn {
    @include line-btn();
  }
}

.page-header {
  position: relative;
  background: white;
  @include flex();
  padding: 16px;
  h1 {
    font-size: $f17;
  }
  svg {
    position: absolute;
    top: 16px;
    right: 25px;
  }
}

.header-shadow {
  box-shadow: 0 0.5px 0 0 rgba(0, 0, 0, 0.20);
}

.booking-agreement {
  margin-bottom: 20px;
  line-height: 19.6px;
  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 15px;
  }
}

.booking-success-page {
  background: white;
  @include flex(center, flex-start, column);
  padding: 40px 24px 32px;
  border-bottom: 1px solid #EBEBEB;

  h3 {
    font-size: 18px;
    margin: 26px 0 8px 0;
  }
  h1 {
    font-size: 22px;
    margin: 0 0 8px 0;
  }

  & > p {
    color: #5E5E5E;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
}

.booking-payment {
  @include flex(center, center, column);
  padding: 24px 20px;
  margin-top: 28px;
  border-radius: 18px;
  background: #FEF8F6;
  color: #222222;
  text-align: center;
  gap: 4px;
  & > p:nth-child(odd) {
    font-size: $f16;
  }
  & > p:nth-child(1) {
    font-weight: bold;
  }
  & > p:nth-child(3) {
    font-weight: bold;
    font-size: $f15;
    span {
      font-size: 28px;
      font-weight: bold;
    }
  }
  & > p:last-child {
    font-size: $f14;
    color: #5E5E5E;
    margin-top: 4px;
  }
}