@import "./variables.scss";

.lucky-spin-area {
  border-bottom: 1px solid #EBEBEB;
  background: var(--bg-light);
  padding: 48px 24px;
  @include flex(center, center, column);
  h3 {
    font-size: 22px;
  }
  & > p:nth-child(2) {
    font-size: $f18;
    margin: 4px 0 20px;
  }
}

.spin-modal-content {
  @include flex(center, center, column);
  gap: 16px;
  margin-bottom: 20px;
  & > div:first-of-type {
    padding: 10px;
    width: 100px;
    height: 100px;
    border-radius: 18px;
    border: 1px solid rgba(34, 34, 34, 0.05);
    background: var(--bg-light);
  }
  span {
    color: #5E5E5E;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.wheel-container {
  position: relative;
  & > svg {
    position: absolute
  }
  #spin-pointer {
    top: -10px;
    left: 128px;
  }
  #spin-button {
    top: 97px;
    left: 106px;
  }
}

.app-links {
  margin: 20px 0 0;
  @include flex();
  gap: 8px;
}

.view-ad-btn {
  width: 100%;
  @include flex();
  gap: 8px;
  & > button {
    @include flex();
    gap: 4px;
  }
  & > button:nth-child(2) {
    @include line-btn();
  }
}

// add 360 deg to each variable to make it spin faster
$degrees: 2190deg, 2250deg, 2310deg, 2370deg, 2430deg, 2490deg;
@for $i from 1 through 6 {

  .spinning-#{$i} {
    animation: spin-#{$i} 4.2s ease-in-out forwards;
    -webkit-animation: spin-#{$i} 4.2s ease-in-out forwards;
  }

  @keyframes spin-#{$i} {
    0% {
      -moz-transform:rotate(0deg);
      -webkit-transform:rotate(0deg);
      -o-transform:rotate(0deg);
      -ms-transform:rotate(0deg);
    }
    100% {
      -moz-transform:rotate(nth($degrees, $i));
      -webkit-transform:rotate(nth($degrees, $i));
      -o-transform:rotate(nth($degrees, $i));
      -ms-transform:rotate(nth($degrees, $i));
    }
  }
}