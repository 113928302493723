@import "./variables.scss";

.ticket-page {
  background: var(--bg-light);
  padding-bottom: 60px;
  article {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .break-line {
    width: 100%;
    border-bottom: 2px dashed #D3D3D3;
    margin: 16px 0;
  }
}

.basic-tabs {
  background: white;
  width: 100%;
  display: flex;
  padding: 0 24px;
  gap: 12px;
  border-bottom: 1px solid #EBEBEB;
  & > div {
    @include flex();
    cursor: pointer;
    flex: 1;
    padding: 16px;
    span {
      font-size: $f16;
      font-weight: bold;
      color: var(--text-light);
    }
  }
  .selected-basic-tab {
    span {
      color: var(--text-main);
    }
    padding-bottom: 13px;
    border-bottom: 3px solid #222;
  }
}

.ticket-detail {
  background: white;
  padding: 32px 24px;
  border-radius: 18px;
  & > div:first-child {
    display: flex;
    h1 {
      flex: 1;
      font-size: $f20;
      font-weight: bold;
    }
    svg {
      cursor: pointer;
    }
  }

  & > dl:first-of-type {
    margin-block-start: 4px;
    margin-block-end: 4px;
    dt {
      color: var(--text-light);
      font-size: $f14;
      margin-bottom: 2px;
    }
    dd {
      color: var(--text-main);
      font-size: $f18;
      font-family: "PretendardSemiBold";
      margin-inline-start: 0;
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
      &.dd-status {
        font-family: "Pretendard";
        color: var(--accent);
        font-weight: bold;
      }
    }
  }
}

.rounded-tag {
  height: 30px;
  margin: 20px 0 30px;
  @include flex(center, flex-start);
  span {
    height: 16px;
    padding: 6px 12px;
    font-size: $f14;
    border: 1px solid #5E5E5E;
  }
  & > span:first-child {
    background: #5E5E5E;
    color: white;
    border-radius: 20px 0 0 20px;
  }
  & > span:last-child {
    color: #5E5E5E;
    background: white;
    border-radius: 0 20px 20px 0;
  }
}

.current-ticket-tag {
  & > span:first-child {
    border-color: var(--accent);
    background: var(--accent);
  }
  & > span:last-child {
    border-color: var(--accent);
    color: var(--accent);
  }
}

.ticket-payment-info {
  border-radius: 8px;
  background: var(--bg-pink);
  padding: 16px;
  @include flex(flex-start, flex-start, column);
  gap: 8px;
  line-height: 140%;
  & > p:first-of-type {
    font-size: $f16;
    font-weight: bold;
  }
  & > p:nth-of-type(2) {
    font-size: $f15;
  }
  & > p:nth-of-type(3) {
    font-size: $f16;
    font-weight: bold;
  }
  & > p:last-of-type {
    font-size: $f14;
    span {
      font-weight: bold;
      border-bottom: 2px solid var(--accent);
      color: var(--accent);
    }
  }

  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 20px;
    li {
      font-size: $f13;
    }
  }

  hr {
    margin-block-start: 0;
    margin-block-end: 0;
    height: 1px;
    width: 100%;
    background: rgba(34, 34, 34, 0.05);
    border: none;
  }
}

.payment-details .price-and-status {
  @include flex(center, space-between);
  span.ticket-pay-status, span.ticket-pay-history-status {
    @include flex();
    height: 14px;
    padding: 5px 6px;
    font-size: $f13;
    border-radius: 34px;
  }
  span.ticket-pay-status {
    color: var(--accent);
    background: var(--bg-pink);
  }
  span.ticket-pay-history-status {
    color: var(--grey);
    background: #EBEBEB;
  }
}

.ticket-detail .payment-details dl:not(:last-of-type) {
  border-bottom: none;
  margin-bottom: 0;
}
.ticket-detail .payment-details dl {
  padding: 16px 0 0;
}


.ticket-history-message {
  display: flex;
  padding: 16px;
  border-radius: 8px;
  background: #EBEBEB;
  p {
    font-size: $f14;
    color: var(--text-main);
    line-height: 140%;
  }
}

.ticket-detail > button {
  @include line-btn();
}


.ticket-cancel-page {
  padding: 24px;
  background: white;
  border-bottom: 1px solid #EBEBEB;
  & > h2 {
    font-size: $f18;
    font-weight: bold;
    color: var(--text-main);
  }
}

.ticket-cancel-form {
  margin-top: 12px;
  @include flex(center, flex-start, column);
  row-gap: 12px;
}

.ticket-cancel-btn {
  margin-top: 12px;
  background: white;
}