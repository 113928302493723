@import "./variables.scss";

.action-ad-detail {
  nav {
    @include flex(center, flex-start);
    padding: 14px 20px;
    background: white;
  }
  img {
    width: 100%;
  }
}

.ad-detail-wrapper {
  padding: 0 24px;
  .ad-detail-header {
    @include flex(center, start);
    padding-bottom: 16px;
    margin-bottom: 24px;
    border-bottom: 1px solid #EBEBEB;
    .ad-icon {
      width: 56px;
      margin-right: 16px;
    }
    .ad-title {
      flex-grow: 1;
      div:first-child {
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 8px;
      }
      div:last-child {
        font-size: 13px;
        font-weight: 500;
        color: #B0B0B0;
      }
    }
    .ad-reward {
      color: #D03660;
      font-size: 15px;
      font-weight: 700;
    }
  }
  .ad-detail-content {
    white-space: pre-wrap;
    .ad-title {
      font-size: 15px;
      font-weight: 600;
      color: #222222;
    }
    .ad-content-item {
      font-size: 13px;
      line-height: 1.4;
      color: #5E5E5E;
      padding-left: 8px;
      margin-bottom: 8px;
    }
  }
  .ad-detail-footer {
    position: fixed;
    bottom: 3rem;
    width: calc(100% - 48px);
  }
}

.buzzvilad-container {
  width: 100%;
  .buzzvilad-image {
    width: 100%;
    margin-bottom: 4px;
    border-radius: 18px;
  }
  .buzzvilad-content {
    margin-bottom: 8px;
    padding: 0 8px;
    .buzzvilad-icon-title {
      @include flex(center, start);
      margin-bottom: 8px;
      .buzzvilad-icon {
        width: 24px;
        height: 24px;
        margin-right: 2px;
      }
      .buzzvilad-title {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .buzzvilad-desc {
      font-size: 14px;
      margin-bottom: 16px;
    }
    .buzzvilad-footer-wrapper {
      @include flex(start, end);
      .buzzvilad-footer {
        @include flex(center, center);
        width: fit-content;
        padding: 8px;
        font-size: 14px;
        color: white;
        border-radius: 8px;
        background-color: #EB4C60;
        .point-img {
          width: 20px;
        }
        .point-number {
          margin-right: 8px;
          font-weight: 500;
        }
        & > span:last-child {
          font-weight: 700;
        }
      }
    }
    .buzzvilad-goto {
      text-align: right;
      font-size: 14px;
      margin: 16px 0;
      color: rgb(59 130 246);
    }
  }
}

.buzzilad-container-playground {
  padding: 8px 24px 0;
}
.buzzilad-container-torygame {
  padding: 16px 24px 0;
  border-bottom: 1px solid #EBEBEB;
}
