@import "./variables.scss";


.yaongcon-page {
  header {
    height: 52px;
    padding: 8px 16px;
    background: white;
    box-sizing: border-box;
    @include flex();
    h1 {
      font-size: 22px;
      font-weight: 900;
      flex: 1;
      & > span {
        font-size: 15px;
        font-weight: 400;
        padding-left: 2px;
      }
    }
    span {
      margin: 0 14px 0 4px;
    }
  }

  & > h2 {
    font-size: $f18;
    letter-spacing: -0.36px;
    margin: 0 24px;
  }
}

.my-coupon-btn {
  position: relative;
  @include light-rounded-box();
  padding: 16px 18px;
  margin: 16px 16px 32px 16px;
  gap: 6px;
  span {
    letter-spacing: -0.32px;
  }
  & > span:nth-child(1) {
    font-weight: bold;
  }
  & > svg:nth-child(2) {
    margin-right: auto;
  }
  & > svg path {
    fill: var(--grey)
  }
}

.coupon-info-message {
  max-width: 220px;
  @include tooltip();
  top: 42px;
  left: 64px;
  &::after {
    top: -7px;
    left: 21px;
  }
}

.brand-list {
  display: flex;
  overflow-x: scroll;
  gap: 8px;
  margin: 16px 0 24px;
  padding: 4px 24px;
  & > div {
    @include flex(center, flex-start, column);
    gap: 8px;
    img, & > div {
      border: 1px solid rgba(34, 34, 34, 0.05);
      border-radius: 50%;
      width: 72px;
      min-height: 72px;
      object-fit: contain;
    }
    .all-brand-img {
      @include flex(center, center, column);
      color: white;
      font-weight: bold;
      background: #222;
    }
    img.selected, div.selected {
      border-style: double;
      outline: 3px solid #222;
      outline-offset: 1px;
    }
    span {
      font-size: $f14;
      white-space: nowrap;
      max-width: 100px;
      text-overflow: ellipsis;
      word-break: break-all;
      overflow: hidden;
    }
  }
}

.brand-coupon-list {
  @include flex(flex-start,center,column);
  & > div {
    padding: 8px 24px;
    display: flex;
    & > img {
      border-radius: 18px;
      border: 1px solid rgba(34, 34, 34, 0.05);
      margin-right: 16px;
      width: 100px;
      min-height: 100px;
      object-fit: contain;
    }
    & > div {
      @include flex(flex-start,center,column);
      & > span:nth-child(2) {
        font-weight: bold;
      }
      & > div {
        @include flex();
        gap: 4px;
      }
    }
  }
}

.coupon-guide {
  border-top: 1px solid #EBEBEB;
  background: var(--bg-light);
  padding: 32px 24px;
  min-height: calc(100vh - 350px);
  display: flex;
  flex-direction: column;
  h4 {
    letter-spacing: -0.32px;
  }
  p {
    font-size: $f15;
    color: var(--grey);
    line-height: 150%;
    margin: 8px 0 16px;
    white-space: pre-wrap;
  }
}

.purchase-confirm {
  @include flex(flex-start, center, column);
  width: 100%;
  hr {
    width: 100%;
    height: 1px;
    background: var(--lightgrey);
    margin: 16px 0 0;
    border: none;
  }
  dl {
    display: grid;
    grid-template-columns: 1fr 80px;
    width: 100%;
    gap: 8px;
    dt {
      font-size: $f14;
      font-family: "PretendardLight";
      color: var(--text-light)
    }
    dd {
      margin-inline-start: 0;
    }
  }
}

.purchase-confirm-popup {
  .popup-content {
    padding-top: 0;
  }
}

.purchase-modal {
  text-align: center;
  .dialog-box {
    min-width: 280px;
  }
  & button:first-of-type {
    @include line-btn();
    margin: 20px 0 10px;
  }
}