@import "./variables.scss";

.fade-bg {
  background: linear-gradient(180deg, rgba(33, 33, 33, 0) 0%,
          rgba(33, 33, 33, 0.2) 75%, rgb(33, 33, 33) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.toast-alert {
  @include flex();
  position: absolute;
  bottom: 80px;
  border-radius: 18px;
  opacity: 0;
  backdrop-filter: blur(20px);
  margin: 24px;
  padding: 15px 20px;
  height: 60px;
  width: calc(100% - 60px);
  background: #222;
  z-index: 20;
  p {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: $f15;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
  transition-duration: 200ms;
  animation: 2s ease-in flash;
}

@keyframes flash {
  0% {
    opacity: 0.9;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}

.basic-btn {
  width: 100%;
  @include basic-btn();
}

.input-field {
  border: var(--border-light);
  color: var(--grey);
  padding: 16px 12px;
  font-size: $f16;
  border-radius: 8px;
}


.date-picker-component {
  width: 100%;
  @include flex(center, space-between);
  gap: 10px;
}

.popup-show {
  position: fixed;
  top: 0;
  min-width: 340px;
  max-width: 500px;
  min-height: fit-content;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.70);
  @include flex(center, flex-end, column);
  z-index: 1000;
}

.popup-hide {
  display: none;
}

.popup-content {
  padding: 18px 24px 24px;
  width: 100%;
}

.popup-container {
  width: 100%;
  background: white;
  border-radius: 20px 20px 0 0;
  @include flex(center, flex-end, column);
  @include appear-animation(100px, 400ms);
}

.popup-header {
  @include flex(center, space-between);
  width: 100%;
  background: white;
  padding: 24px 24px 12px 24px;
  border-radius: 20px 20px 0 0;
}

.date-picker-calendar {
  background: white;
  width: 100%;
  min-height: 402px;


  .rdp-months, .rdp-table {
    width: 100%;
    min-width: 100%;

    tr td button {
      font-size: $f20;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .rdp-month.rdp-caption_start.rdp-caption_end {
    width: 100%;
    padding: 10px 12px 40px;
  }
  .rdp-head_row {
    color: #C2C2C2;
  }
  .rdp-day.rdp-day_today {
    color: var(--primary);
    font-weight: 500 !important;
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background: var(--bg-pink);
  }

  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover {
    color: var(--primary);
    background: var(--bg-pink);
    font-size: $f24;
  }
}

.select-options {
  background: white;
  width: 100%;
  padding: 0 24px 24px 24px;
  ul {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    max-height: 400px;
    overflow-y: scroll;
  }
  li {
    padding: 18px 0;
  }
}

.select-box {
  width: 100%;
  @include flex();
  min-width: 200px;
  border-radius: 8px;
  border: 1px solid #DDD;
  padding: 16px 12px;
  span {
    height: 20px;
  }
  svg {
    transform: rotate(-90deg);
    margin-left: auto;
    margin-right: 10px;
  }
}



.dialog-box {
  position: relative;
  font-size: $f14;
  margin: 20px 40px;
  animation-name: animate;
  animation-duration: 0.3s;
  overflow: hidden;
  padding: 24px;
  border-radius: 18px;
  background: #FFF;
  backdrop-filter: blur(40px);
  max-width: 480px;
}

.dialog-bg {
  @include flex();
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.70);
}

.dialog-title {
  color: #222;
  width: 100%;
  height: 35px;
  font-size: $f20;
  font-weight: 700;
}

.dialog-content {
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
}

.alert-dialog-box {
  text-align: center;
  min-width: 280px;
}

.alert-dialog-content {
  p {
    white-space: pre-wrap;
    margin-bottom: 20px;
  }
  button:not(:first-of-type) {
    @include line-btn();
    margin-top: 10px;
  }
}

@keyframes animate {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}


// CUSTOM CHECKBOX INPUT
.checkbox-wrapper {
  @include flex(flex-start, flex-start);
  & > div:last-child {
    min-height: 24px;
    padding-top: 3px;
    @include flex(flex-start, center, column);
  }
  label {
    font-size: $f16;
  }
  p {
    font-size: $f14;
    color: var(--grey);
    font-weight: 400;
    margin-top: 3px;
  }
}


.checkbox {
  display: flex;
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked ~ .custom-checkmark:after {
    display: block;
  }
}

.custom-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22.5px;
  width: 22px;
  background-color: #FFFFFF;
  border: 1px solid #222222;
  border-radius: 4px;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.checkbox input:checked ~ .custom-checkmark {
  background: #222222;
  &:after {
    border: solid #FFFFFF;
    left: 9px;
    top: 4px;
    width: 4px;
    height: 10px;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}



.accordion-container {
  width: 100%;
  .accordion-head {
    width: 100%;
    padding: 21px 24px 24px 24px;
    @include flex(center, space-between);
    & > div {
      height: 24px;
      width: 24px;
      @include flex(center, flex-end);
      cursor: pointer;
      padding-right: 5px;
    }
    svg {
      transition: 200ms ease-in-out;
    }
    svg path {
      fill: var(--grey);
    }
  }

  .accordion-body {
    width: 100%;
    transition: 500ms ease-in-out;
    overflow: hidden;
    padding: 0 24px 24px 24px;
    ul {
      overflow: hidden;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
  &.accordion-close {
    .accordion-body {
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &.accordion-open {
    .accordion-body {
      max-height: 500px;
    }
  }
}


// TEXT FIELD
.text-field-wrap {
  width: 100%;
}

.text-field {
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #DDD;
  padding: 10px 12px 13px;

  &:has(input:focus) {
    border: 2px solid #222222;
    padding: 9px 11px 12px;
  }

  label {
    color: #717171;
    margin-bottom: 8px;
    font-family: "Pretendard";
    font-size: $f12;
  }
  input {
    width: 100%;
    @include basic-input();
  }

  svg {
    position: absolute;
    right: 12px;
    bottom: calc(50% - 10px);
  }
  &:has(label) {
    svg {
      bottom: calc(50% - 18px);
    }
  }
}

.input-error .text-field, .select-error {
  border: 2px solid var(--error);
  label {
    color: var(--error);
  }
}

.text-area-input {
  position: relative;
  width: 100%;
  margin: 12px 0 34px;
  box-sizing: border-box;
  background: var(--bg-light);
  border: 1px solid var(--lightgrey);
  padding: 10px 10px 27px;
  border-radius: 8px;
  textarea {
    width: 100%;
    font-family: "Pretendard";
    color: var(--text-main);
    font-size: $f15;
    background: transparent;
    border: none !important;
    outline: none !important;
  }
  span.text-count {
    color: var(--text-light);
    font-size: $f15;
    position: absolute;
    bottom: 10px;
    right: 10px;
    text-align: right;
  }
  &:focus {
    border: 2px solid #222;
  }
}

.text-area-error {
  border: 2px solid var(--error);
}

.text-field-helper, .text-field-error-text {
  font-family: "Pretendard";
  font-size: $f14;
  margin-top: 8px;
}
.text-field-helper {
  color: #717171;
}
.text-field-error-text {
  color: var(--error);
}

.text-light {
  color: var(--text-light);
}
.text-extra-light {
  color: #B0B0B0;
}
.text-grey {
  color: var(--grey) !important;
}
.text-accent {
  color: var(--accent) !important;
  font-weight: bold;
}

.toggle-btn {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 34px;
  min-width: 48px;
  min-height: 34px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: var(--accent);
    }
    &:focus + .slider {
      box-shadow: none;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #B0B0B0;
    -webkit-transition: 300ms;
    transition: 300ms;
    &:before {
      position: absolute;
      content: "";
      height: 28px;
      width: 28px;
      left: 3px;
      bottom: 3px;
      background-color: #FFFFFF;
      -webkit-transition: 300ms;
      transition: 300ms;
    }
    &.round {
      border-radius: 40px;
      &:before {
        border-radius: 50%;
      }
    }
  }
}
