@import "./variables.scss";

.swimming-pool-page {
  width: 100%;
  min-width: 340px;
  height: 100%;
  position: relative;
  background: #222222;
  article {
    background: white;
  }
}

.pool-images {
  min-height: 300px;
  height: 50%;
  min-width: 100%;
  background-image: url("../images/image_main_2png.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  position: relative;
  color: white;
  & > div:nth-child(2) {
    position: absolute;
    bottom: 40px;
    left: 16px;
    @include flex(flex-start, center, column);
    & > p {
      margin: 5px 0 16px 0;
    }
    & > div {
      @include flex(center, flex-start);
      flex-wrap: wrap;
      gap: 4px;
    }
  }
  img, svg {
    position: absolute;
    top: 40px;
  }
  img {
    right: 60px;
    width: 27px;
    height: 27px;
  }
  svg {
    right: 20px;
    height: 27px;
    width: 27px;
    path {
      fill: white;
    }
  }
}

.new-ticket-mark {
  @include flex();
  position: absolute;
  top: 31px;
  right: 13px;
  width: 20px;
  height: 20px;
  background: var(--primary);
  border-radius: 50%;
  span {
    font-size: $f13;
  }
}

.new-ticket-msg {
  @include tooltip();
  top: 74px;
  right: 13px;
  &::after {
    top: -7px;
    right: 10px;
  }
}

.pool-tag {
  border-radius: 43px;
  padding: 6px 8px;
  font-size: $f12;
  background: #FFFFFF10;
}

.pool-description {
  min-width: 340px;
  position: relative;
  margin-top: -20px;
  border-radius: 24px 24px 0 0;
  background: white;
  width: 100%;
  height: 50%;
  @include appear-animation(60px);

  & > div:first-of-type {
    width: 100%;
    @include flex(flex-start, flex-start, column);
    row-gap: 8px;
    padding: 24px;
    font-size: 0.875rem;
    border-bottom: var(--border-light);
    p {
      display: flex;
      align-items: center;
      svg {
        margin-right: 4px;
      }
      span {
        margin-left: 10px;
        color: var(--grey);
        display: flex;
        align-items: center;
      }
    }
  }

  hr {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

.booking-options {
  width: 100%;
  position: relative;
  padding: 24px;
  background: white;
  & > div:first-child {
    @include flex(center, flex-start);
    margin-bottom: 12px;
    word-break: keep-all;
    small {
      font-size: $f13;
      font-weight: 500;
      margin-left: 8px;
    }
  }
  & > div:not(:first-child) {
    @include flex(center, flex-start);
    gap: 8px;
    flex-wrap: wrap;

    .time-box {
      @include flex(flex-start, flex-start, column);
      flex: 1;
      gap: 10px;
      border: var(--border-light);
      -webkit-border: var(--border-light);
      border-radius: 8px;
      padding: 16px 12px;
      font-size: $f13;
      color: var(--grey);
      span:first-of-type {
        font-size: $f16;
        font-weight: bold;
      }
      &.selected-time {
        border: 2px solid var(--primary);
        -webkit-border: 2px solid var(--primary);
        -webkit-transform: translateZ(0);
        color: var(--primary);
        padding: 15px 11px;
      }
      @media (max-width: 380px) {
        font-size: $f12;
      }
    }
  }
}


.booking-number-select {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 140px;
  grid-auto-rows: 50px;
  align-items: center;
  &:last-of-type {
    margin-bottom: 15px;
  }
  & > div {
    @include flex(center, space-between);
    & > span {
      font-size: $f14;
    }
    & b {
      font-size: $f18;
    }
  }
  small {
    font-size: $f14;
  }
}

.booking-number-label {
  margin: 12px 0 -10px;
  small {
    font-size: 13px;
    font-weight: 400 !important;
  }
}

.discount-coupons {
  width: 100%;
  margin-bottom: 12px;
  @include flex(flex-start, flex-start, column);
  gap: 4px !important;
  & > div {
    width: 100%;
    @include flex(center, flex-start);
    border-radius: 8px;
    background: var(--bg-pink);
    padding: 12px 16px;
    gap: 16px;
    color: var(--primary);
    font-size: $f15;
    font-family: "PretendardLight";

    & > div:first-child {
      background: var(--primary);
      @include flex();
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }
}

.link-btn {
  display: flex;
  padding: 5px 0 30px;
  background: white;
  & > button {
    @include flex();
    gap: 2px;
    flex: 1;
    height: 20px;
    background: white;
    border: none;
    font-size: $f15;
    &:first-of-type {
      border-right: var(--border-light);
    }
  }
}


.pool-detail {
  background: white;
  @include flex(flex-start, flex-start, column);
  width: 100%;
  max-width: 500px;
  padding: 32px 24px 16px;
  & > h3 {
    margin-bottom: 12px;
  }
  & div.pricing-dates {
    margin-top: 17px;
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    p {
      font-size: $f13;
      text-align: right;
      color: var(--grey)
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 32px;
    thead {
      background: #F7F7F7;
      th {
        font-weight: normal;
        border-top: var(--border-light);
      }
    }
    th, td {
      font-size: $f15;
      padding: 10px;
      border-bottom: var(--border-light);
    }
    tr td:first-child {
      font-weight: bold;
    }
  }
}

.pool-pricing-swiper {
  width: 100%;
  position: relative;
  & > h3 {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
  }
  .swiper-left-btn, .swiper-right-btn {
    z-index: 10;
    position: absolute;
    top: 88px;
  }
  .swiper-left-btn {
    left: 0;
    transform: rotate(180deg);
  }
  .swiper-right-btn {
    right: 0;
  }
}

.bench-images {
  display: grid;
  width: 100%;
  max-width: 500px;
  gap: 2px;
  grid-template-rows: repeat(2, 120px);
  grid-template-columns: 50% 50%;
  div {
    position: relative;
    border-radius: 4px;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &::after {
      position: absolute;
      background: black;
      color: white;
      bottom: 4px;
      right: 4px;
      width: 20px;
      height: 20px;
      @include flex();
      padding: 3px;
      border-radius: 50%;
    }
    &:first-of-type::after {
      content: "1";
    }
    &:nth-of-type(2)::after {
      content: "2";
    }
    &:last-of-type::after {
      content: "3";
    }
  }
}

.bench-types {
  font-size: $f15;
  @include flex();
  flex-wrap: wrap;
  margin: 6px 0 32px 0;
  span {
    width: 10px;
    height: 10px;
    background: black;
    color: white;
    @include flex();
    padding: 3px;
    border-radius: 50%;
    margin-right: 3px;
    font-size: 12px;
  }
  p {
    @include flex();
    &:not(:last-of-type) {
      margin-right: 8px;
    }
    font-family: "PretendardLight";
  }
}


.pool-booking-terms {
  padding-bottom: 70px;
  h3 {
    @include flex(center, flex-start);
    svg {
      margin-right: 6px;
      path {
        fill: var(--grey);
      }
    }
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-bottom: var(--border-light);
    background: var(--bg-light);
    border-top: 1px solid #EBEBEB;
    ul, ol {
      padding-inline-start: 20px;
    }
    p, li {
      color: var(--grey);
      font-size: $f14;
      line-height: 140%;
    }
  }
}

.facilities-images {
  height: 260px;
  width: 100%;
  border-radius: 8px;
  .pool-img-swiper {
    height: 260px;
    img {
      height: 260px;
      width: 100%;
      object-fit: cover;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 8px;
    }
    .swiper-pagination-bullet {
      background: #B0B0B0;
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background: #FFFFFF;
      }
    }
  }
}

.facility-description {
  display: flex;
  flex-wrap: wrap;
  font-size: $f14;
  dt {
    width: 25px;
    height: 22px;
    svg {
      width: 16px;
    }
    svg path {
      fill: black;
    }
  }
  dd {
    width: calc(100% - 40px);
    word-break: break-all;
    flex-wrap: wrap;
    gap: 4px;
    span {
      padding: 5px 8px;
      border-radius: 4px;
      background: #F7F7F7;
    }
    a {
      cursor: pointer;
      color: #222222;
      text-decoration: none;
    }
  }
  dt, dd {
    @include flex(center, flex-start);
    margin-inline-start: 0;
    padding: 4px 0;
  }
}

.download-btn {
  @include flex();
  gap: 6px;
  width: 100%;
  padding-bottom: 50px;
  background: white;
  svg {
    width: 150px;
    height: 50px;
  }
}


.personal-info-policy {
  span {
    font-weight: bold;
    margin-bottom: 10px;
  }
  ol, ul {
    font-size: $f14;
    li {
      margin-bottom: 10px;
      font-family: "PretendardLight";
    }
  }
  ol {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 16px;
  }
  ul {
    margin-top: 10px;
    list-style: none;
    padding-inline-start: 0;
    li {
      text-indent: -7px;
    }
    li::before {
      content: "-";
      margin: 0 5px 0 -5px;
    }
  }

  .checkbox-wrapper {
    margin: 20px 0;
  }
}

.alert-modal {
  text-align: center;
  
  .dialog-content {
    & > p {
      margin: 16px;
      font-size: 1rem;
    }
    & > .text-emphasis {
      font-size: larger;
      font-weight: bold;
    }
  }
}

.popup-modal {
  .dialog-box {
    padding: 0;
    border-radius: 0;
  }
  .dialog-title {
    display: none;
  }
  .dialog-content {
    & > .img-container {
      padding: 20px 0 0 20px;
      background-color: #FFD221;
      line-height: 0;
      & > img {
        width: 300px;
      }
    }
    & > .buttons {
      @include flex(center, space-evenly);
      height: 44px;
      & > span:first-child {
        color: #AEAEAE;
      }
      & > .line {
        height: 32px;
        border-right: 1px solid #AEAEAE;
      }
    }
  }
}