@import "./variables.scss";

.my-page {
  article {
    width: 100%;
    height: 100%;
    background: var(--bg-light);
    padding: 32px 16px;
  }

  .user-info {
    position: relative;
    @include flex(flex-start, flex-start);
    gap: 20px;
    margin-bottom: 32px;
    & > img {
      border-radius: 35px;
      object-fit: cover;
      object-position: center;
    }
    .camera-icon {
      position: absolute;
      border-radius: 50%;
      height: 23px;
      width: 24px;
      @include flex();
      bottom: -4px;
      left: 64px;
      border: 1px solid #FFF;
      background: #717171;
      padding: 2px 1px 2px 2px;
      svg {
        width: 18px;
        height: 18px;
        cursor: pointer;
        path {
          fill: #FFFFFF;
        }
      }
    }
    & > div {
      height: 92px;
      @include flex(flex-start, center, column);
      gap: 8px;
      h2 {
        display: flex;
        align-items: flex-end;
        font-size: 22px;
        white-space: pre-wrap;
        svg {
          cursor: pointer;
          path {
            fill: #B0B0B0;
          }
        }
      }
      p {
        white-space: pre-wrap;
        line-height: 140%;
        font-size: $f15;
        font-weight: 400;
      }
    }
  }

  .white-card {
    margin-bottom: 16px;
    padding: 10px;
  }
}

.points-and-coupons, .tory-game-victories, .settings-list {
  div {
    padding: 17px 14px;
    width: 100%;
    @include flex();
    gap: 8px;
    & > span:first-of-type {
      margin-right: auto;
    }
    & > span:last-of-type {
      font-size: $f18;
      font-weight: bold;
    }
    & > svg:last-child path {
      fill: var(--grey);
    }
  }
}

.friend-invites {
  padding: 17px 0 !important;
   & > div:first-child {
     width: 100%;
     border-bottom: 1px solid #EBEBEB;
     padding: 0 24px 17px;
     span {
       font-weight: bold;
       margin-right: 4px;
     }
     span:last-child {
       color: #D03660;
     }
   }

  .invites-info {
    display: flex;
    width: 100%;
    position: relative;
    & > div:not(:last-child) {
      flex: 1;
      @include flex(center, center, column);
      gap: 4px;
      padding: 24px 0;
      & > span:nth-child(1) {
        font-size: $f14;
      }
      & > span:nth-child(2) {
        color: #B0B0B0;
        font-weight: bold;
        font-size: 22px;
        strong {
          color: var(--text-main);
        }
      }
    }
    & > :last-child {
      position: absolute;
      width: 1px;
      height: 33px;
      top: 33px;
      background: #D9D9D9;
      left: 50%;
    }
  }

  .invites-buttons {
    display: flex;
    gap: 8px;
    padding: 0 24px 14px;
    button {
      @include flex();
      gap: 4px;
    }
    button:first-of-type {
      @include line-btn;
    }
    button:last-of-type {
      @include secondary-btn();
      svg path {
        fill: white;
      }
    }
  }
}

.settings-list {
  & > div {
    span {
      font-weight: 500 !important;
    }
    & > span:first-of-type {
      font-size: $f16;
    }
    & > span.version-tag {
      padding: 3px 6px;
      font-size: $f14;
      border: 1px solid var(--accent);
      border-radius: 20px;
    }
    & > span:last-child {
      font-size: $f14;
      font-family: "PretendardLight";
    }
  }
}


.my-points-page {
  button.info-btn {
    position: absolute;
    right: 16px;
  }

  & > article:first-of-type {
    @include flex(center, flex-start, column);
    padding: 16px 16px 40px;
    background: var(--bg-light);
  }

  & > article.point-history {
    background: var(--bg-light);
    .basic-tabs {
      border-radius: 18px 18px 0 0;
    }

    .point-item {
      padding: 20px 24px;
      border-bottom: 1px solid #EBEBEB;
      background: #FFF;
      div {
        @include flex();
        margin: 3px 0;
        gap: 4px;
        h4 {
          margin-right: auto;
        }
      }
      .text-light {
        font-size: $f12;
      }
      .text-grey {
        font-size: $f14;
      }
    }
  }
}

.victories-page {
  & > article:first-of-type {
    @include flex(center, flex-start, column);
    padding: 16px 16px 40px;
    background: var(--bg-light);
  }

  & > article.victory-history {
    background: var(--bg-light);
    .basic-tabs {
      border-radius: 18px 18px 0 0;
    }
    .victories-list {
      background: white;
      list-style: none;
      margin-block-start: 0;
      margin-block-end: 0;
      padding-inline-start: 0;
      li {
        border-bottom: 1px solid #EBEBEB;
      }
    }
  }
}

.victory-item {
  padding: 16px 24px;
  & > div:first-of-type {
    display: grid;
    grid-template-columns: 60px 1fr 80px;
    grid-template-rows: 1fr 1fr;
    column-gap: 16px;
    .text-light {
      margin-top: 12px;
    }
    span:not(.text-light) {
      font-weight: bold;
    }
  }
  .text-light, p {
    font-size: $f12;
  }
  & > div:last-of-type {
    margin-top: 12px;
    @include light-rounded-box();
    padding: 6px 8px;
  }
}

.top-victory-item {
  padding: 12px 24px;
  & > div:first-of-type {
    display: grid;
    grid-template-columns: 20px 70px 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 4px;
    .text-grey {
      font-size: $f14;
      margin-top: 4px;
    }
    & > span:not(.text-grey) {
      margin-top: 8px;
      font-weight: bold;
    }
    & > div {
      @include flex();
      font-weight: bold;
    }
  }
}

.settings-page, .policy-page, .delete-account-page {
  background: var(--bg-light);
  min-height: 100vh;
}

.setting-box {
  background: #FFFFFF;
  padding: 20px 24px;
  border-bottom: 1px solid #EBEBEB;
  p.text-light {
    font-size: $f14;
    margin-top: 8px;
    line-height: 140%;
    max-width: 270px;
  }
  & > div svg path {
    fill: var(--grey);
  }
}

.push-settings > div.setting-box {
  @include flex(center, space-between);
}


.setting-box .login-icons {
  display: flex;
  gap: 16px;
  & > div {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    @include flex();
    cursor: pointer;
    &:first-of-type {
      background: #FEEB00;
    }
    &:nth-of-type(2) {
      background: #03C75A;
    }
    &:nth-of-type(3), &:last-of-type {
      background: #FFFFFF;
      border: 1px solid var(--lightgrey);
    }

  }
}

.policy-list {
  & > div,
  & > a {
    @include flex(center, space-between);
    background: #FFFFFF;
    padding: 17px 24px;
    border-bottom: 1px solid #EBEBEB;
    svg path {
      fill: var(--grey);
    }
    text-decoration: none;
    color: inherit;
  }
}

.agreement-detail {
  background: #FFFFFF;
  padding: 20px 24px 30px;
  & > div {
    @include flex(flex-start, flex-start, column);
  }
}

.delete-account-form {
  padding: 32px 24px;
  background: #FFFFFF;
  h3 {
    margin-bottom: 8px;
  }
  & > p.text-light {
    font-size: $f15;
    margin-bottom: 16px;
  }

  & > button {
    margin-top: 24px;
  }
}

.summoner-code-page article {
  height: calc(100vh - 80px);
  min-height: fit-content;
  @include flex(flex-start, flex-start, column);
  padding: 24px 13px;
  h1 {
    font-size: 22px;
    font-weight: 700;
    @include flex();
    white-space: pre-wrap;
    margin: 0 10px 15px;
  }

  & > label {
    margin: 10px;
    color: var(--text-light);
  }

  & > div .text-field {
    padding: 20px 10px;
    background: #FEF8F6;
  }

  .code-guidelines {
    margin: 27px 0 20px;
  }

  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 25px;
    li {
      color: var(--grey);
      font-size: $f15;
      line-height: 140%;
    }
  }

  & > button:last-of-type {
    margin: auto 12px 0;
    width: calc(100% - 24px);
  }
}

.summoner-code-page {
  .alert-modal .dialog-box {
    min-width: 300px;
    h3 {
      @include flex();
    }
    p {
      max-width: 180px;
      font-weight: 400;
      margin: 10px auto 25px;
      color: #222222;
      white-space: pre-wrap;
    }
  }
}

@media (max-width: 360px) {
  .summoner-code-page article {
    h1 {
      font-size: 21px;
    }
  }
}

// 친구소환 화면
.invite-page {
  background: #E3DDEB;
  width: 100%;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;

  & > div {
    position: relative;
    background: #FFF;

    border-radius: 18px;
    margin-top: -40px;
    text-align: center;
    & > div:first-of-type {
      padding: 24px 24px 0;
    }
    & > div:last-of-type {
      padding: 24px;
      & > div:first-child {
        border-radius: 16px;
        background: #F7F3FD;
        @include flex();
        padding: 24px 16px;
        margin-bottom: 20px;
        p {
          text-align: center;
          font-size: $f14;
          word-break: keep-all;
        }
      }
      & .friends-current {
        @include flex(center, flex-start);
        gap: 16px;
        & > span {
          font-weight: bold;
        }
        .info-bubble {
          flex: 1;
          color: var(--grey);
          line-height: 20px;
          vertical-align: middle;
          b {
            color: var(--text-main);
            font-weight: 900;
            font-size: 20px;
          }
        }
      }
    }
    h1 {
      font-size: 22px;
    }
  }

  & > img:first-of-type {
    mix-blend-mode: darken;
    margin: 0 auto;
  }

  #reward-icon {
    position: absolute;
    top: -32px;
    left: 5px;
  }

  .divider {
    width: 100%;
    height: 0;
    border-top: 2px dashed #E3DDEB;
  }
}

.invite-terms {
  background: #D5CCE0;
  padding: 32px 24px 40px;
  h3 {
    font-size: $f15;
  }
  ul {
    margin-top: 10px;
    list-style: disc;
    padding-inline-start: 16px;
    p, li {
      color: var(--grey);
      font-size: $f14;
      line-height: 140%;
    }
  }
}