@import "./variables.scss";

.layout {
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  max-width: 500px;
}

.page-container {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  overflow-y: overlay;
  scroll-behavior: smooth;
  background: white;
}

.nav-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  height: 80px;
  background: white;
  display: flex;
  background-blend-mode: hard-light;
  box-shadow: 0px -0.5px 0px 0px rgba(0, 0, 0, 0.30);
  backdrop-filter: blur(20px);
  z-index: 10;

  button {
    cursor: pointer;
    flex: 1;
    background: white;
    border: none !important;
    color: var(--grey);
    @include flex(center, center, column);
    row-gap: 5px;
    &.selected-page {
      color: var(--primary);
    }
  }
}

.tory-float-btn {
  width: 56px;
  height: 56px;
  position: fixed;
  bottom: 93px;
  border-radius: 50%;
  cursor: pointer;
}